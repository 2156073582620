<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>Unit Roda 4</b-card-title>
          </b-card-header>
          <b-card-body class="px-3 py-1">
            <b-row class="mb-3">
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="CheckCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ cars.available }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tersedia
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-warning">
                      <feather-icon size="24" icon="FileTextIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ cars.rented }}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tersewa
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="LayersIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{ cars.total }}</h4>
                    <b-card-text class="font-small-3 mb-0"> Total </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-dark">
                      <feather-icon size="24" icon="LinkIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ cars.onprocess }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dalam Proses
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="SettingsIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ cars.maintenance }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dalam Perbaikan
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>Unit Roda 2</b-card-title>
          </b-card-header>
          <b-card-body class="px-3 py-1">
            <b-row class="mb-3">
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="CheckCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ motorcycles.available }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tersedia
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-warning">
                      <feather-icon size="24" icon="FileTextIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ motorcycles.rented }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Tersewa
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="LayersIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ motorcycles.total }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0"> Total </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-dark">
                      <feather-icon size="24" icon="LinkIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ motorcycles.onprocess }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dalam Proses
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col xl="4" sm="6">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="SettingsIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ motorcycles.maintenance }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Dalam Perbaikan
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col cols="3">
              <b-form-group label="Nopol" label-for="police_no">
                <b-form-input
                  id="police_no"
                  placeholder="Nopol"
                  v-model="filter.police_no"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Nama" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Nama"
                  v-model="filter.name"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Kategori" label-for="category">
                <b-form-select
                  id="category"
                  v-model="filter.selectedCategory"
                  :options="filter.optionsCategory"
                />
                <!-- Selected: <strong>{{ filter.selectedCategory }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Status" label-for="status">
                <b-form-select
                  id="status"
                  v-model="filter.status"
                  :options="filter.optionsStatus"
                />
                <!-- Selected: <strong>{{ filter.selectedCategory }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Start Date (STNK)" label-for="start_date">
                <flat-pickr
                  id="registration_start_date"
                  v-model="filter.registrationStartDate"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Awal"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="End Date (STNK)" label-for="end_date">
                <flat-pickr
                  id="registration_end_date"
                  v-model="filter.registrationEndDate"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Akhir"
                />
                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Start Date (KIR)" label-for="kir_start_date">
                <flat-pickr
                  id="kir_start_date"
                  v-model="filter.kirStartDate"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Awal"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="End Date (KIR)" label-for="kir_end_date">
                <flat-pickr
                  id="kir_end_date"
                  v-model="filter.kirEndDate"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Akhir"
                />
                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-1"
                @click="
                  getInquiry(
                    1,
                    filter.police_no,
                    filter.name,
                    filter.selectedCategory,
                    filter.status,
                    filter.registrationStartDate,
                    filter.registrationEndDate,
                    filter.kirStartDate,
                    filter.kirEndDate
                  )
                "
              >
                Cari
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right mb-1">
        <router-link to="/unit/add">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="success"
            pill
            class="px-3 mr-1"
          >
            Tambah Unit
          </b-button>
        </router-link>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          class="px-3"
          @click="exportExcel()"
        >
          Export Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <span v-else-if="props.column.field === 'status'" class="text-nowrap">
            <b-badge :variant="statusVariant(props.row.status)">
              <span class="text-nowrap"> {{ props.row.status_name }}</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item v-bind:to="{ path: '/unit/' + props.row.id }">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteProduct(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Hapus</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ meta.from }} - {{ meta.to }} </span>
              <span class="text-nowrap">&nbsp;dari {{ meta.total }} data </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(
                      value,
                      filter.police_no,
                      filter.name,
                      filter.selectedCategory,
                      filter.status,
                      filter.registrationStartDate,
                      filter.registrationEndDate,
                      filter.kirStartDate,
                      filter.kirEndDate
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";


export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    flatPickr,
    StatisticCardHorizontal,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-dark",
        1: "light-success",
        2: "light-warning",
        3: "light-dark",
        4: "light-primary",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Kode",
          field: "code",
          width: "200px",
        },
        {
          label: "Nopol",
          field: "police_no",
          width: "150px",
        },

        {
          label: "Merk",
          field: "brand",
          width: "150px",
        },
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "Kategori",
          field: "category_name",
          width: "250px",
        },
        {
          label: "Kilometer",
          field: "mileage",
          width: "150px",
        },
        {
          label: "Status",
          field: "status",
          width: "100px",
        },
        {
          label: "",
          field: "action",
          width: "50px",
        },
      ],
      filter: {
        police_no: "",
        name: "",
        selectedCategory: "",
        optionsCategory: [],
        status: "",
        optionsStatus: [
          {
            value: 1,
            text: "Tersedia",
          },
          {
            value: 2,
            text: "Tersewa",
          },
        ],
        registrationStartDate: "",
        registrationEndDate: "",
        kirStartDate: "",
        kirEndDate: "",
      },
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      rows: [],
      errors: "",
      errMessage: "",
      searchTerm: "",
      cars: 0,
      motorcycles: 0,
    };
  },
  created() {
    this.getAllPermissions();
    this.getStatistic();
    this.getInquiry(1, "", "", "", "", "", "", "", "");
    this.getCategories();
  },
  methods: {
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry(
      $page,
      $police_no,
      $name,
      $category,
      $status,
      $regStart,
      $regEnd,
      $kirStartDate,
      $kirEndtDate
    ) {
      var urllink;
      urllink = "units?page=" + $page;
      if ($police_no != "") {
        urllink += "&police_no=" + $police_no;
      }
      if ($name != "") {
        urllink += "&name=" + $name;
      }
      if ($category != "") {
        urllink += "&category=" + $category;
      }
      if ($status != "") {
        urllink += "&status=" + $status;
      }
      if ($regStart != "") {
        urllink += "&registration_start_date=" + $regStart;
      }
      if ($regEnd != "") {
        urllink += "&registration_end_date=" + $regEnd;
      }
      if ($kirStartDate != "") {
        urllink += "&kir_start_date=" + $kirStartDate;
      }
      if ($kirEndtDate != "") {
        urllink += "&kir_end_date=" + $kirEndtDate;
      }
      this.$http
      .get(
          urllink
        )
        .then((res) => {
          this.rows = res.data.data;
          //console.log(res.data.data)
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          console.log("error")
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getStatistic() {
      this.$http
        .get("unit/statistic")
        .then((response) => {
          this.cars = response.data.data.car;
          this.motorcycles = response.data.data.motorcycle;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getCategories() {
      this.$http.get("unit/categories/all").then((response) => {
        // console.log(response.data.data)
        this.filter.optionsCategory.push({ value: "", text: "All" });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.filter.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.filter.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.filter.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.filter.optionsCategory)
      });
    },
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    deleteProduct($id) {
      this.$http
        .post("unit/" + $id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Unit berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/units";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
    exportExcel() {
      var urllink = "unit/export?";      

      if (this.filter.selectedCategory != "") {
        urllink += "&category=" + this.filter.selectedCategory;
      }
      if (this.filter.name != "") {
        urllink += "&name=" + this.filter.name;
      }
      if (this.filter.status != "") {
        urllink += "&status=" + this.filter.status;
      }
      if (this.filter.police_no != "") {
        urllink += "&police_no=" + this.filter.police_no;
      }
      if (this.filter.registrationStartDate != "") {
        urllink += "&registration_start_date=" + this.filter.registrationStartDate;
      }
      if (this.filter.registrationEndDate != "") {
        urllink += "&registration_end_date=" + this.filter.registrationEndDate;
      }
      if (this.filter.kirStartDate != "") {
        urllink += "&kir_start_date=" + this.filter.kirStartDate;
      }
      if (this.filter.kirEndDate != "") {
        urllink += "&kir_end_date=" + this.filter.kirEndDate;
      }
      this.$http
        .get(urllink)
        .then((response) => {
          // console.log(response.data.data.url)
          const url = response.data.data.url;
          const link = document.createElement("a");
          link.href = url;
          const fileName = ".xlsx";
          link.setAttribute("download", fileName);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success export excel",
              variant: "success",
            },
          });
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>